body {
  min-width: 1200px;
}
.purple-header{
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background: linear-gradient(-45deg, rgba(78, 84, 200, .9), rgba(128, 137, 255, .9));
  display: block;
  height: 115px;
}

.navbar {
  box-shadow: none !important;
}

.btn-primary {
  color: white;
  background: linear-gradient(-45deg, rgba(78, 84, 200, .9), rgba(128, 137, 255, .9));
  font-size: 20px;
  margin-left: 10px;
}

.white {
  color: white !important;
}

.logo-login{
  width: 50px;
  margin-bottom: 10px;
}

.login-form {
  margin-top: 80px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  color: white;
}

.login-form label {
  background: rgb(232, 240, 254) !important;
  font-size: 32px;
  top: -5px !important;
  border-radius: 5px;
}

.bg-main {
  background: linear-gradient(-45deg, rgba(78, 84, 200, .9), rgba(128, 137, 255, .9));
}
.main-menu {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
}

.makeStyles-mainMenu-1 div.MuiListItem-button,.main-menu div.MuiListItem-button, {
  width: 200px;
  background: white;
  border: 1px solid rgb(78, 84, 200);
  color: black !important;
}

.col-right{
  text-align: right;
}

.griddle-next-button,.griddle-previous-button {
  background: linear-gradient(-45deg, rgba(78, 84, 200, .9), rgba(128, 137, 255, .9));
  color:white;
  border: none;
}

.dateDescription {
  margin-left: 20px;
}

.custom-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 70px;
}
.custom-date input {
  width: 150px;
}

.custom-date .custom-col {
  display: flex;
  flex-direction: row;
  width: 350px;
  align-items: center;
  justify-content: space-around;
}

.finance-table .griddle-table {
  width: 80% !important;
}

.finance-table .griddle-table th:nth-child(3),.finance-table .griddle-table td:nth-child(3) {
  padding-left: 10px;
}

.button-act a {
  color: white;
}

.button-act a:hover {
  color: white;
  text-decoration: none !important;
}